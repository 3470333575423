<template>
    <div>
        <input style="display:none" type="file" id="upload-input" @change="uploadInput($event)" multiple>
        <div 
      id="listing"
      :class="user.viewMode"
      v-if="uploadTasksLen > 0">
      <div class="item header">
        <div></div>
        <div>
          <p class="active name">
            <span>{{ $t('files.uploadedFiles') }}</span>
            <i v-if="!isUploadPaused"
                class="material-icons always-show"
                @click="pauseUpload">pause</i>
            <i v-else 
                class="material-icons always-show"
                @click="resumeUpload">play_arrow</i>
            <i class="material-icons always-show" 
                style="margin: 0"
                @click="cancelUpload">close</i>
          </p>
          <p class="size">
            <span>{{ $t('files.size') }}</span>
          </p>
          <p class="modified">
            <span>{{ $t('files.uploadedIn') }}</span>
          </p>
        </div>
      </div>
      <h2 v-if="uploadTasksLen > 0">Uploads</h2>
      <upload-item v-for="(uploadItemTask) in uploadItems"
        :key="base64(uploadItemTask.file.name)"
        v-bind:fileTask="uploadItemTask"
        @cancel="onFileUploadCancel">
      </upload-item>
    </div>
    </div>
</template>
<script>
import { UploadController } from '../../api/upload';
import { mapState } from 'vuex'
import UploadItem from './ListingUploadItem'
import { files as api } from '@/api'
import buttons from '@/utils/buttons'

export default {
    name: 'listing-upload',
    components: { UploadItem },
    data() {
        return {
            uploadItems: [],
            isUploadPaused: false,
        };
    },
    computed: {
        ...mapState(['user']),
        uploadTasksLen() {
            return this.uploadItems.length;
        },
    }, 
    methods: {
        async cancelUpload() {
            await this.uploadController.cancelUpload();
        },
        async pauseUpload() {
            this.isUploadPaused = true;
            await this.uploadController.pauseUpload();
        },
        async resumeUpload() {
            this.isUploadPaused = false;
            await this.uploadController.startUpload();
        },
        async onFileUploadCancel(fileTask) {
            await this.uploadController.cancelFileTask(fileTask);
        },
        uploadInput (event) {
            this.upload(event.currentTarget.files, '');
        },
        async upload(files, base) {
            let isConflict = await this.checkConflict(files, base);
            if (isConflict) {
                let shouldOverwrite = await this.showOverwritePrompt();
                if (!shouldOverwrite) return;
            }
            this.preventTabClosing();
            buttons.loading('upload');
            await this.handleUpload(files, base); 
            this.clearInput();           
            buttons.success('upload');
            this.cancelPreventTabClosing();
            this.$store.commit('setReload', true);
        },
        async handleUpload(files, base) {
            try {            
                this.uploadController = new UploadController(files, this.$route.path + base, this.uploadItems);
                this.uploadController.startUpload();
                await this.uploadController.wait();
            } catch(error) {
                this.$showError(error)
            }
        },
        clearInput() {
            document.querySelector('#upload-input').value = null;
        },
        windowListener(event) {
            event.preventDefault();
            event.returnValue = 'Are you sure you want to leave the page?';
        },
        preventTabClosing() {
            window.addEventListener('beforeunload', this.windowListener);
        },
        cancelPreventTabClosing() {
            window.removeEventListener('beforeunload', this.windowListener);
        },
        async checkConflict(files, base) {
            let req = await api.fetch(this.$route.path + base);
            let items = req.items;
            if (typeof items === 'undefined' || items === null) {
                items = []
            }

            let conflict = false
            for (let i = 0; i < files.length; i++) {
                let res = items.findIndex(function hasConflict (element) {
                return (element.name === this)
                }, files[i].name)

                if (res >= 0) {
                conflict = true
                break
                }
            }
            return conflict;
        },
        showOverwritePrompt() {
            return new Promise((resolve) => {
                this.$store.commit('showHover', {
                    prompt: 'replace',
                    confirm: (event) => {
                        event.preventDefault();
                        this.$store.commit('closeHovers');
                        resolve(true);
                    },
                    cancel: (event) => {
                        event.preventDefault();
                        this.$store.commit('closeHovers');
                        resolve(false);
                    }
                });
            });
        },
        base64: function (name) {
            return window.btoa(unescape(encodeURIComponent(name)))
        },
    }
}
</script>