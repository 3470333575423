import { fetchJSON, fetchURL, removePrefix, remFromArr } from "./utils";
import url from "../utils/url";

async function createPost(fileUrl, file, overwrite = false, signal = null) {
    fileUrl = removePrefix(fileUrl);
    let filename = url.encodeRFC5987ValueChars(file.name);
    let resp =  await fetchJSON(`/api/upload/new${fileUrl+filename}?override=${overwrite}`, {
        method: 'POST',
        signal: signal
    });
    let transferId = resp.transfer_id;
    return transferId;
}

var partSize = 5 * Math.pow(10, 6);

async function postPart(fileUrl, blob, fileName, partIndex, transferId, signal = null) {
    fileUrl = removePrefix(fileUrl)
    let filename = url.encodeRFC5987ValueChars(fileName);
    await fetchURL(`/api/upload/part${fileUrl+filename}`, {
        method: 'POST',
        headers: {
            'X-Transfer-Id': transferId,
            'X-Part-Id': partIndex,
            'Content-Type': 'application/octet-stream'
        },
        body: blob,
        signal: signal
    });
}

async function finishPost(fileUrl, file, transferId, signal = null) {
    fileUrl = removePrefix(fileUrl);
    let filename = url.encodeRFC5987ValueChars(file.name);
    await fetchURL(`/api/upload/finish${fileUrl+filename}`, {
        method: 'POST',
        headers: {
            'X-Transfer-Id': transferId
        },
        signal: signal
    });
}

async function abortPost(fileUrl, file, signal = null) {
  fileUrl = removePrefix(fileUrl);
  let filename = url.encodeRFC5987ValueChars(file.name);
  await fetchURL(`/api/upload/abort${fileUrl+filename}`, {
    method: 'POST',
    signal: signal
  });
}

export class FileUploadTask {
    constructor(file, fileUrl, onPartUploaded = null) {
        this.fileUrl = fileUrl;
        this.file = file;
        this.partIndex = 0;
        this.transferId = null;
        this.interrupted = false;
        this.finished = false;
        this.partsCount = file.size / partSize;
        this.onupload = null;
        this.hasStarted = false;
        this.estimatedDownloadEnd = Date.now() + 30 * 1000;
        this.onPartUploaded = onPartUploaded;
    }

    async start() {
        this.interrupted = false;
        if (this.finished) return;
        this.abortController = new AbortController();
        if (!this.transferId) {
            this.transferId = await createPost(this.fileUrl, this.file, true);
            this.hasStarted = true;
        }
        for (; this.partIndex < this.partsCount; this.partIndex++) {
            const timestamp = Date.now();
            const part = this.file.slice(this.partIndex * partSize, Math.min((this.partIndex+1) * partSize, this.file.size));
            try {
                await postPart(this.fileUrl, part, this.file.name, this.partIndex, this.transferId, this.abortController.signal);
            } catch (e) {
                if (this.interrupted) {
                    return;
                }
                else throw `Unrecoverable part upload error ${e}`;
            }
            if (this.onPartUploaded) this.onPartUploaded(Date.now() - timestamp);
        }
        await finishPost(this.fileUrl, this.file, this.transferId, this.onupload);
        this.finished = true;
    }

    pause() {
        this.interrupted = true;
        this.abortController.abort();
    }

    async abort() {
        this.finished = true;
        this.pause();
        await abortPost(this.fileUrl, this.file, this.onupload);
    }

    getProgress() {
        return this.partIndex / this.partsCount || 0;
    }

}

export class UploadController {
    constructor(files,
                fileUrl,
                uploadTasksArr) {
        this.files = [];
        for (let i = 0; i < files.length; i++) {
            this.files.push(files[i]);
        }
        this.files.sort((f1, f2) => f1.size - f2.size);
        this.uploadTasksArr = uploadTasksArr;
        this.uploadTasksArr.push(...this.files.map(f => new FileUploadTask(f, fileUrl, this.updateEstimatedTimes.bind(this))));
        this.currentFileUploadTask = null;
        this.isPaused = false;
        this.uploadFinishResolve = null;
        this.uploadFinishReject = null;
        this.uploadFinishPromise = new Promise((resolve, reject) => {
            this.uploadFinishResolve = resolve;
            this.uploadFinishReject = reject;
        });
    }

    getUploadTasks() {
        return this.uploadTasksArr;
    }

    async startUpload() {
        this.isPaused = false;
        while (this.uploadTasksArr.length > 0) {
            if (this.isPaused) return false;
            this.currentFileUploadTask = this.uploadTasksArr[0];
            await this.currentFileUploadTask.start();
            if (this.currentFileUploadTask.finished) {
                this.uploadTasksArr.shift();
            }
            if (this.isPaused) return;
        }
        this.uploadFinishResolve();
        return true; 
    }

    async wait() {
        await this.uploadFinishPromise;
    }

    pauseUpload() {
        this.isPaused = true;
        if (this.currentFileUploadTask) this.currentFileUploadTask.pause();
    }

    async cancelFileTask(fileTask) {
        if (fileTask.hasStarted) await fileTask.abort();
        remFromArr(this.uploadTasksArr, fileTask, (t1, t2) => t1.file.size - t2.file.size);
        if (this.uploadTasksArr.length == 0) return await this.startUpload();
    }

    async cancelUpload() {
        this.pauseUpload();
        for (let t of this.uploadTasksArr.slice().reverse()) {
            await this.cancelFileTask(t);
        }
        return await this.startUpload();
    }

    updateEstimatedTimes(timeDelta) {
        let cumulativeFileSize = 0;
        for (let fileTask of this.uploadTasksArr) {
            cumulativeFileSize += fileTask.file.size * (1 - fileTask.getProgress());
            fileTask.estimatedDownloadEnd = Date.now() + Math.floor(cumulativeFileSize / partSize * timeDelta);
        }
    }

}