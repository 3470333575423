<template>
    <div class="item upload-item"
        tabindex="0"
        draggable="false"
        :aria-label="name"
        :style="{'background': progressBackground}">
    <div>
      <i class="material-icons">{{ icon }}</i>
    </div>

    <div>
      <p class="name">
          {{ fileTask.file.name }}
      </p>

      <p class="size" data-order="-1">
          {{ fileSize }}
      </p>

      <p class="modified">
        {{ estimatedTime }}
      </p>
      <i class="material-icons always-show cancel-icon"
        @click="onUploadCancel">close</i>
    </div>
  </div>
</template>
<script>
import filesize from 'filesize';
import moment from 'moment'
// import { mapMutations, mapGetters, mapState } from 'vuex';
export default {
    name: 'upload-item',
  data: function () {
    return {
      touches: 0
    }
  },
  computed: {
      icon() {
          const state = 'running';
          switch(state) {
              case 'queued': return 'cloud_queue';
              case 'finished': return 'cloud_done';
              case 'running': return 'cloud_upload';
              default: return 'cloud_off';
          }
      },
      progressBackground() {
          return `linear-gradient(to right, var(--surfacePrimary) ${this.fileTask.getProgress() * 100}%, transparent 0)`
      },
      fileSize() {
          return filesize(this.fileTask.file.size);
      },
      estimatedTime() {
          return moment(this.fileTask.estimatedDownloadEnd).fromNow();
      }
  },
  methods: {
      onUploadCancel() {
          this.$emit('cancel', this.fileTask);
      }
  },
  props: ['fileTask'],
}
</script>